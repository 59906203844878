import { graphql, PageProps } from 'gatsby';
import React, { FC } from 'react';

import type { ImageBoxData } from '~src/components/elements';
import {
  BoxSection,
  Hero,
  HeroData,
  SocialSection,
  SocialSectionData,
} from '~src/components/sections';
import type { SeoData } from '~src/layouts/components/seo';
import Layout from '~src/layouts/page-layout';
import type { StrapiHomePage } from '~src/types/generated/graphql-gatsby';

type Data = {
  strapiHomePage: StrapiHomePage;
};

const HomePage: FC<PageProps<Data>> = ({
  data: { strapiHomePage },
  location,
}) => {
  const seoData = strapiHomePage.seo as SeoData;
  const heroData = strapiHomePage.hero as HeroData;
  const imageBoxData = strapiHomePage.imageBox as ImageBoxData;
  const socialData = strapiHomePage.gallery as SocialSectionData;
  const imageBoxDuoData = strapiHomePage.imageBoxDuo as ImageBoxData[];

  return (
    <Layout pathname={location.pathname} seoData={seoData}>
      <Hero data={heroData} />
      <BoxSection isFirstBox isReversed dataTop={imageBoxData} />
      <SocialSection
        data={socialData}
        pathname={location.pathname}
        activeGallery="Social Gallery"
      />
      <BoxSection
        isReversed
        dataTop={imageBoxDuoData[0]}
        dataBottom={imageBoxDuoData[1]}
      />
    </Layout>
  );
};

export default HomePage;

export const homePageQuery = graphql`
  query HomePage($locale: String!) {
    strapiHomePage(locale: { eq: $locale }) {
      seo {
        title
        description
        featuredImage {
          alt
          image {
            localFile {
              ...FeaturedImg
            }
          }
        }
      }
      hero {
        title
        subtitle
        coverImage {
          alt
          desktop {
            localFile {
              ...DesktopCoverImg
            }
          }
          phone {
            localFile {
              ...PhoneCoverImg
            }
          }
        }
      }
      imageBox {
        title
        text
        images {
          id
          alt
          image {
            localFile {
              ...SectionImg
            }
          }
        }
        link {
          label
          link {
            pageID
          }
        }
      }
      rooms {
        preTitle
        title
        link {
          label
          link {
            pageID
          }
        }
        rooms {
          id
        }
      }
      gallery {
        title
        link {
          label
          link {
            pageID
          }
        }
        social {
          socialLinks {
            id
            name
            url
          }
          title
        }
        images {
          id
          alt
          image {
            previewImg: localFile {
              ...SocialPreviewImg
            }
            thumbImg: localFile {
              ...ThumbImg
            }
            slideImg: localFile {
              ...SlideImg
            }
          }
        }
      }
      newsletter {
        preTitle
        title
        image1 {
          alt
          image {
            name
          }
        }
        link1 {
          label
          link {
            pageID
          }
        }
        image2 {
          alt
          image {
            name
          }
        }
        link2 {
          label
          link {
            pageID
          }
        }
      }
      imageBoxDuo {
        title
        text
        images {
          id
          alt
          image {
            localFile {
              ...SectionImg
            }
          }
        }
        link {
          label
          link {
            pageID
          }
        }
      }
    }
  }
`;
